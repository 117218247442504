import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Toast from 'react-toast'
import { observer } from 'mobx-react'
import config from '~/config'
import { appStore, authenticationStore, InvalidToken, ProjectArchived } from '~/stores'
import { Center, Label } from '~/ui/components'
import { SubmitResult } from '~/ui/form'
import { useQueryParam } from '~/ui/hooks'
import LoginForm from './LoginForm'
import LoginFormModel from './LoginFormModel'
import OtherLoginOptionList from './OtherLoginOptionList'

const LoginScreen = observer(() => {

  const history = useHistory()
  const [t]     = useTranslation('auth')

  const forgotPin = React.useCallback((email: string) => {
    history.push(`/auth/forgot-pin?email=${encodeURIComponent(email)}`)
  }, [history])

  const app = appStore.app

  //------
  // Form

  const showEmailAndPinFields = appStore.app?.auth.emailAndPincode !== false
  const showOtherLoginOptions = appStore.app != null && appStore.app.auth.oAuthProviders.length > 0
  const singleOAuthProvider   = appStore.app != null && appStore.app.auth.oAuthProviders.length === 1 ? appStore.app.auth.oAuthProviders[0] : null

  React.useEffect(() => {
    if (showEmailAndPinFields || singleOAuthProvider == null) { return }

    const url = new URL(`${config.urls.api}/v3/oauth/${singleOAuthProvider.name}/init`)
    url.searchParams.set('platform', 'web')
    if (app != null) {
      url.searchParams.set('app', app.id)
    }
    document.location.replace(url)
  }, [showEmailAndPinFields, singleOAuthProvider, app])

  const formModel = React.useMemo(
    () => new LoginFormModel(),
    [],
  )

  const toastInvalidToken = React.useCallback(() => {
    Toast.show({
      type:   'error',
      title:  t('errors.invalid-token.title'),
      detail: t('errors.invalid-token.detail'),
    })
  }, [t])

  const toastProjectArchived = React.useCallback(() => {
    Toast.show({
      type:   'error',
      title:  t('errors.project-archived.title'),
      detail: t('errors.project-archived.detail'),
    })
  }, [t])

  const afterSubmit = React.useCallback((result: SubmitResult) => {
    if (result.status === 'error' && result.error instanceof InvalidToken) {
      toastInvalidToken()
    }
    if (result.status === 'error' && result.error instanceof ProjectArchived) {
      toastProjectArchived()
    }
  }, [toastInvalidToken, toastProjectArchived])

  //------
  // Magic login

  const appReady      = appStore.ready
  const {loginStatus} = authenticationStore
  const [authToken_query, setAuthToken_query] = useQueryParam('auth')

  const magicLogin = React.useRef(authToken_query != null)

  React.useEffect(() => {
    if (!appReady) { return }
    if (loginStatus === 'logging-in') { return }

    if (authToken_query != null) {
      setAuthToken_query(null)
      authenticationStore.logIn({token: authToken_query}).then(result => {
        afterSubmit(result)
      })
    }
  }, [afterSubmit, appReady, authToken_query, history, loginStatus, setAuthToken_query])

  //------
  // Rendering

  function render() {
    if (!showEmailAndPinFields && singleOAuthProvider != null) {
      return (
        <Center flex>
          <Label caption>{t('misc:redirecting')}</Label>
        </Center>
      )
    }

    return (
      <LoginForm
        model={formModel}
        magicLogin={magicLogin.current}
        emailAndPin={showEmailAndPinFields}
        otherLoginOptions={showOtherLoginOptions ? <OtherLoginOptionList/> : undefined}
        requestForgotPin={forgotPin}
      />
    )
  }

  return render()

})

export default LoginScreen